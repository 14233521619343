import { createAxiosInstance } from '@/utils/axios';
import config from '@/config';

export const axios = createAxiosInstance(config.likeshopUrl, {
  camelizeKeys: true,
  decamelizeRequests: true,
});

export const LikeShopGalleryType = Object.freeze({
  INSTAGRAM: 'INSTAGRAM_LIKESHOP',
  TIKTOK: 'TIKTOK_LIKESHOP',
});

const urls = {
  [LikeShopGalleryType.INSTAGRAM]: {
    getLikeShopGallery: ({ accountName }) => `/api/accounts/${accountName}/galleries/likeshop`,
    getGalleryMediaDetail: ({ accountName, galleryMediaId }) =>
      `/api/accounts/${accountName}/gallery_media/${galleryMediaId}`,
    trackPageView: ({ accountName, galleryId }) =>
      `/api/accounts/${accountName}/galleries/${galleryId}/track_view`,
    trackProductLinkClick: ({ accountName, galleryId }) =>
      `/api/accounts/${accountName}/galleries/${galleryId}/track_click`,
    captureEmail: ({ accountName }) =>
      `/api/accounts/${accountName}/galleries/likeshop/capture_email`,
  },
  [LikeShopGalleryType.TIKTOK]: {
    getLikeShopGallery: ({ accountName }) => `/api/tiktok/${accountName}/galleries/tiktok`,
    getGalleryMediaDetail: ({ accountName, galleryMediaId }) =>
      `/api/tiktok/${accountName}/gallery_media/${galleryMediaId}`,
    trackPageView: ({ accountName, galleryId }) =>
      `/api/tiktok/${accountName}/galleries/${galleryId}/track_view`,
    trackProductLinkClick: ({ accountName, galleryId }) =>
      `/api/tiktok/${accountName}/galleries/${galleryId}/track_click`,
    captureEmail: ({ accountName }) => `/api/tiktok/${accountName}/galleries/tiktok/capture_email`,
  },
};

const getUrl = (galleryType, action, params) => {
  if (!urls[galleryType]) {
    throw new Error(`Invalid gallery type: ${galleryType}`);
  }
  if (!urls[galleryType][action]) {
    throw new Error(`${action} not implemented for ${galleryType}`);
  }
  return urls[galleryType][action](params);
};

function encodeAxiosParam(value) {
  let v = value;
  if (toString.call(value) === '[object Date]') {
    v = value.toISOString();
  } else if (typeof value === 'object') {
    v = JSON.stringify(value);
  }
  return encodeURIComponent(v);
}

export default {
  async getLikeShopGallery({ galleryType, accountName, limit, previewOptions }, axiosConfig = {}) {
    const url = getUrl(galleryType, 'getLikeShopGallery', { accountName });
    const params = { media_limit: limit, previewOptions };
    return axios.get(url, {
      ...axiosConfig,
      params,
      paramsSerializer: (obj) => {
        return Object.entries(obj)
          .filter(
            ([, value]) =>
              value !== undefined &&
              value !== null &&
              (Array.isArray(value) ? value.length > 0 : true)
          )
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              return value.map((v) => `${key}[]=${encodeAxiosParam(v)}`).join('&');
            }
            return `${key}=${encodeAxiosParam(value)}`;
          })
          .join('&');
      },
    });
  },

  async loadMoreGalleryMedia({ nextUrl }, axiosConfig) {
    return axios.get(nextUrl, axiosConfig);
  },

  async getGalleryMediaDetail({ galleryType, accountName, galleryMediaId }, axiosConfig) {
    const url = getUrl(galleryType, 'getGalleryMediaDetail', { accountName, galleryMediaId });
    return axios.get(url, axiosConfig);
  },

  async trackPageView({ galleryType, accountName, galleryId }, axiosConfig) {
    const url = getUrl(galleryType, 'trackPageView', { accountName, galleryId });
    const data = {};
    return axios.post(url, data, axiosConfig);
  },

  async trackProductLinkClick(
    { galleryType, accountName, galleryId, galleryMediaId, product },
    axiosConfig
  ) {
    const url = getUrl(galleryType, 'trackProductLinkClick', { accountName, galleryId });
    let type = 'LIKESHOP';
    if (galleryType === LikeShopGalleryType.TIKTOK) {
      type = 'TIKTOK_LIKESHOP';
    }
    const data = {
      productId: String(product.id),
      mediaProductId: String(product.mediaProductId),
      galleryMediaId: String(galleryMediaId),
      type,
    };
    return axios.post(url, data, axiosConfig);
  },

  async captureEmail({ galleryType, accountName, email }, axiosConfig) {
    const url = getUrl(galleryType, 'captureEmail', { accountName });
    const data = { email };
    return axios.post(url, data, axiosConfig);
  },
};
